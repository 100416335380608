import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type ZoomOutProps = {
    className?: string;
};

const ZoomOut: FunctionComponent<ZoomOutProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'zoom-out'}
            viewBox="0 0 250 250"
        >
            <path
                fill="#FFFFFF"
                d="M93.3 9C46.8 9 9 46.7 9 93.1c0 46.3 37.8 84.1 84.3 84.1s84.3-37.8 84.3-84.1C177.6 46.7 139.8 9 93.3 9z"
                fillOpacity="0.3"
            />
            <path d="M154.7 163.1l-.9.8c-16.9 14.4-38.3 22.3-60.5 22.3-51.4 0-93.2-41.7-93.2-93S41.9.1 93.2.1s93.2 41.7 93.2 93c0 23.4-8.7 45.6-24.5 62.8l-.9.9 89.1 87-6.2 6.3-89.2-87zM93.3 9C46.8 9 9 46.7 9 93.1c0 46.3 37.8 84.1 84.3 84.1s84.3-37.8 84.3-84.1C177.6 46.7 139.8 9 93.3 9z" />
            <rect width="70" height="10" x="57.8" y="90.6" />
        </svg>
    );
};
export default ZoomOut;
