import { type FC, useEffect, useState, useRef, useCallback } from 'react';
import { PinchZoom } from './PinchZoom';
import { Spinner } from 'dibs-elements/exports/Spinner';
import ZoomOut from 'dibs-icons/exports/legacy/ZoomOut';
import { Link } from 'dibs-elements/src/Link';
import classnames from 'classnames';

import dibsCss from 'dibs-css';
import styles from './styles/PinchZoomMobile.scss';

const PinchZoomMobile: FC<{
    pinchZoomEnabled: boolean;
    isItemZoomedIn: boolean;
    photoPath: string;
    onZoomIn: () => void;
    onZoomOut: () => void;
    className?: string;
}> = ({ pinchZoomEnabled, isItemZoomedIn, photoPath, onZoomIn, onZoomOut, className }) => {
    const [loaded, setLoaded] = useState(false);
    const zoomModelRef = useRef<$TSFixMe>(null);

    useEffect(() => {
        const img = new Image();
        img.onload = () => {
            setLoaded(true);
        };
        img.src = photoPath;

        return () => {
            img.onload = null;
        };
    }, [photoPath]);

    const onClick = useCallback(() => {
        zoomModelRef?.current?.reset();
        onZoomOut();
    }, [onZoomOut]);

    const style = { backgroundImage: `url("${photoPath}")` };

    if (!loaded) {
        return <Spinner dark containerClass={classnames(styles.spinner, dibsCss.relative)} />;
    }

    return (
        <div
            className={classnames(
                dibsCss.relative,
                dibsCss.flex1,
                dibsCss.flex,
                styles.pinchZoomContainer,
                className
            )}
        >
            {isItemZoomedIn && (
                <Link
                    className={classnames(
                        styles.zoomOut,
                        dibsCss.zBump,
                        dibsCss.absolute,
                        dibsCss.bottomXsmall,
                        dibsCss.rightXsmall,
                        dibsCss.flex,
                        dibsCss.itemsCenter,
                        dibsCss.justifyCenter,
                        dibsCss.roundedFull
                    )}
                    onClick={onClick}
                >
                    <ZoomOut className={styles.zoomOutIcon} />
                </Link>
            )}
            <PinchZoom
                zoomModelRef={zoomModelRef}
                className={classnames(styles.zoomImageWrapper, dibsCss.flex1, dibsCss.flex)}
                enabled={pinchZoomEnabled}
                onZoomIn={onZoomIn}
                onZoomOut={onZoomOut}
                maxZoom={3}
            >
                <div className={classnames(styles.zoomImage, dibsCss.flex1)} style={style} />
            </PinchZoom>
        </div>
    );
};

export { PinchZoomMobile };
